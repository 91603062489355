import React, { useContext, useState } from 'react'
import { faInfoCircle, faWarning } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import FaceRecognitionButtonDesktop from './components/FaceRecognitionButtonDesktop'
import TextRecognitionButtonDesktop from './components/TextRecognitionButtonDesktop'
import NoFilterButtonDesktop from './components/NoFilterButtonDesktop'
import SelfieUploader from 'components/UI/SelfieUploader/SelfieUploader'
import ProfileStore from 'containers/MyAccount/components/UpdateUser/ProfileStore'
import storesContext from 'providers/storesContext'
import Button from 'components/UI/Button'
import InputWrapper from 'components/UI/Forms/InputWrapper'
import EventDetailsStore from '../../EventDetailsStore'

export enum filterTypes {
  FACE_RECOGNITION = 'FACE_RECOGNITION',
  TEXT = 'TEXT',
  NO_FILTERS = 'NO_FILTERS',
}

type EventDetailsFirstRenderDesktopProps = {
  filterByFaceRecognition: () => void
  filterByTagValue: () => void
  handleClearFilters: () => void
  store: EventDetailsStore
}

const EventDetailsFirstRenderDesktop = ({
  filterByFaceRecognition,
  filterByTagValue,
  handleClearFilters,
  store,
}: EventDetailsFirstRenderDesktopProps) => {
  const { t } = useTranslation()
  const { authStore } = useContext(storesContext)!
  const [selectedAsProfilePicture, setSelectedAsProfilePicture] = useState(false)
  const [profileStore] = useState(() => new ProfileStore(authStore, true))

  const { tagValue, event } = store
  const disableSearch = store.isLoadingEventPhotographs || store.isLoadingEventAlbums
  const defaultFilter = event.faceRecognitionFilter
    ? filterTypes.FACE_RECOGNITION
    : event.textFilter
    ? filterTypes.TEXT
    : filterTypes.NO_FILTERS
  const [selectedFilter, setSelectedFilter] = useState(defaultFilter)

  const titleName =
    selectedFilter === filterTypes.FACE_RECOGNITION
      ? t('Upload a selfie')
      : selectedFilter === filterTypes.TEXT
      ? t('Enter a text')
      : t('No filters')

  function handleChangeTagValue(tagValue: string) {
    store.setIsFilteringByFaceRecognition(false)
    store.setTagValue(tagValue)
    if (tagValue.trim() === '') {
      handleClearFilters()
    }
  }

  const handleFinishFaceRecognitionUpload = () => {
    if (authStore.isAuthenticated() && selectedAsProfilePicture) {
      profileStore.uploadImage().then(() => {
        const loggedUser = authStore.getLoggedUser()
        loggedUser.profilePictureUrl = profileStore.profilePictureUrl!
        profileStore.updateUser(loggedUser)
      })
    }
    filterByFaceRecognition()
  }

  const onSuccess = () => {
    selectedFilter === filterTypes.FACE_RECOGNITION
      ? handleFinishFaceRecognitionUpload()
      : selectedFilter === filterTypes.TEXT
      ? filterByTagValue()
      : handleClearFilters()
  }

  return (
    <div className="flex w-full bg-white p-3 gap-4">
      <div className="flex flex-col w-1/2 gap-3">
        <div className="flex gap-2">
          <span className="p-2 bg-primary_dark text-lumepic-light_black font-bold rounded-full h-6 w-6 flex items-center justify-center">
            1
          </span>
          <span>{t('Choose a search filter')}</span>
        </div>
        {event.faceRecognitionFilter && (
          <FaceRecognitionButtonDesktop
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
          />
        )}
        {event.textFilter && (
          <TextRecognitionButtonDesktop
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
          />
        )}
        <NoFilterButtonDesktop
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        />
      </div>
      <div className="w-1/2 flex flex-col gap-3">
        <div className="flex gap-2">
          <span className="p-2 bg-primary_dark text-lumepic-light_black font-bold rounded-full h-6 w-6 flex items-center justify-center">
            2
          </span>
          <span>{t('Follow instructions and find your photos')}</span>
        </div>
        <div className="w-full border rounded-lg">
          <h3 className="text-center text-xl font-semibold text-lumepic-light_black p-1.5 bg-lumepic-semi_medium_grey border-b">
            {titleName}
          </h3>
          <div className="p-3">
            {selectedFilter === filterTypes.FACE_RECOGNITION && event.faceRecognitionFilter && (
              <SelfieUploader
                store={profileStore}
                selectedAsProfilePicture={selectedAsProfilePicture}
                setSelectedAsProfilePicture={setSelectedAsProfilePicture}
              />
            )}
            {selectedFilter === filterTypes.TEXT && event.textFilter && (
              <>
                <div className="flex gap-1.5 items-start mb-4">
                  <FontAwesomeIcon icon={faInfoCircle} className="text-lumepic-grey mt-0.5" />
                  <p className="text-sm font-light">
                    {t(
                      'Type your unique number or word that distinguished your outfit on this event.'
                    )}
                  </p>
                </div>
                <InputWrapper
                  className="flex-grow-1"
                  onChange={handleChangeTagValue}
                  inputStore={tagValue}
                  placeholder={t('e.g. 2083').toString()}
                  onPressEnter={disableSearch ? () => {} : onSuccess}
                  disabled={disableSearch}
                  extraClassNames="truncate"
                />
              </>
            )}
            {selectedFilter === filterTypes.NO_FILTERS && (
              <div className="flex flex-col items-center gap-3 my-2">
                <FontAwesomeIcon icon={faWarning} className="text-4xl text-lumepic-grey" />
                <p className="text-lumepic-light_black text-center text-lumepicSm mx-10">
                  {t(
                    'Choosing this option will make it more difficult and time-consuming to locate your photos. Additionally, you will not have access to package discounts.'
                  )}
                </p>
              </div>
            )}
          </div>
        </div>
        <Button btnType="PrimaryAction" onClick={onSuccess}>
          {t('Search')}
        </Button>
      </div>
    </div>
  )
}

export default observer(EventDetailsFirstRenderDesktop)
