import React, { useCallback, useContext, useEffect } from 'react'
import { observer } from 'mobx-react'
import storesContext from 'providers/storesContext'
import { ProgressInfo } from 'containers/MyAlbums/CreateOrUpdateAlbumSteps/Steps/UploadImageToAlbum/UploadImageToAlbumStore'
import { isNil } from 'lodash'
import ProfileStore from 'containers/MyAccount/components/UpdateUser/ProfileStore'
import ImageUploaded from 'components/UI/ImageUploaded/ImageUploaded'
import ConfigService from 'config'
import ImageUploader from '../ImageUploader/ImageUploader'

type SelfieUploaderProps = {
  store: ProfileStore
  selectedAsProfilePicture?: boolean
  setSelectedAsProfilePicture?: React.Dispatch<React.SetStateAction<boolean>>
}

const SelfieUploader = ({
  store,
  selectedAsProfilePicture,
  setSelectedAsProfilePicture,
}: SelfieUploaderProps) => {
  const { authStore } = useContext(storesContext)!

  useEffect(() => {
    if (authStore.isAuthenticated()) {
      const image = ConfigService.getRecognitionPicture(authStore.getLoggedUser().id)
      const img = new Image()
      img.onload = function () {
        authStore.changeRecognitionImage(image)
      }
      img.src = image
    }
  }, [])

  const auxSetProgressInfo = useCallback((pginfo: ProgressInfo) => {
    store.changeProgressInfo(pginfo)
  }, [])

  const onSubmitImage = (image: File) => {
    store.changeImageSubmitted(true)
    store.changeImage(image)
    const progressInfo = {
      percentage: 0,
      fileName: image.name,
      requestSent: false,
      error: false,
      size: image.size,
      type: image.type,
    }
    auxSetProgressInfo(progressInfo)
  }

  useEffect(() => {
    if (store.imageSubmitted && !isNil(store.image)) {
      store.uploadImage(true)
    } else if (store.imageSubmitted && isNil(store.image)) {
      store.changeImageSubmitted(false)
    }
  }, [auxSetProgressInfo, store.image])

  let imageDisplayView
  !authStore.recognitionImageUrl
    ? (imageDisplayView = <ImageUploader onUpload={onSubmitImage} />)
    : (imageDisplayView = (
        <ImageUploaded
          onUpload={onSubmitImage}
          store={store}
          isSelfie
          setSelectedAsProfilePicture={setSelectedAsProfilePicture}
          selectedAsProfilePicture={selectedAsProfilePicture}
        />
      ))

  return <>{imageDisplayView}</>
}

export default observer(SelfieUploader)
