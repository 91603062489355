import { PieChart } from '@mui/x-charts'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Toggle, { DisplayMode } from 'components/UI/Toggle/Toggle'
import { isMobileDevice } from 'shared/utility'

type SalesByActivityPieChartProps = {
  salesByActivity: { activity: string; salesAmountInUsd: number; salesCount: number }[]
}

const SalesByActivityPieChart = ({ salesByActivity }: SalesByActivityPieChartProps) => {
  const { t } = useTranslation()

  const [displayMode, setDisplayMode] = useState<DisplayMode>(DisplayMode.USD)

  const pieData = salesByActivity.map(({ activity, salesCount, salesAmountInUsd }) => ({
    label: activity,
    value: displayMode === DisplayMode.USD ? parseFloat(salesAmountInUsd.toFixed(2)) : salesCount,
  }))

  const pieChartPalette = [
    '#D4C581',
    '#E5DCB3',
    '#C1B077',
    '#636363',
    '#DDD29D',
    '#F8F8F8',
    '#FFC20F',
  ]
  return (
    <>
      <h2 className="p-2 font-semibold justify-start items-start text-base text-lumepic-light_black">
        {t('Sales By Activity')}
      </h2>
      <Toggle displayMode={displayMode} setDisplayMode={setDisplayMode} />
      <PieChart
        series={[
          {
            data: pieData,
            highlightScope: { faded: 'global', highlighted: 'item' },
          },
        ]}
        margin={{ top: 15, right: 15, bottom: 70, left: 15 }}
        slotProps={{
          legend: {
            direction: 'row',
            position: { vertical: 'bottom', horizontal: 'middle' },
            padding: 0,
            itemMarkWidth: 7,
            itemMarkHeight: 7,
            markGap: 5,
            itemGap: 5,
            labelStyle: {
              fontSize: 14,
            },
          },
        }}
        tooltip={{ trigger: 'item' }}
        colors={pieChartPalette}
        height={isMobileDevice() ? 300 : 350}
      />
    </>
  )
}
export default SalesByActivityPieChart
