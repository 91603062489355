import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { useTranslation } from 'react-i18next'
import Button from 'components/UI/Button'
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import { isMobileDevice } from 'shared/utility'

type MetricCardProps = {
  icon: IconDefinition
  value?: number | string
  label: string
  showDetailsButton?: boolean
  onDetailsClick?: () => void
}

const MetricCard = ({ icon, value, label, showDetailsButton, onDetailsClick }: MetricCardProps) => {
  const { t } = useTranslation()

  return (
    <div className="relative flex flex-col bg-white shadow hover:shadow-xl transition-shadow duration-300 ease-in-out rounded-lg justify-center items-center gap-3 p-4 md:px-4 md:py-8">
      <div className="flex flex-col md:flex-row items-center gap-3 mb-3">
        <FontAwesomeIcon
          icon={icon}
          className={`text-lumepic-success_dark_green opacity-85 ${
            !isMobileDevice() ? 'fa-2x' : 'fa-1x'
          }`}
        />
        <div className="text-left text-wrap">
          <span className="text-base md:text-2xl font-semibold text-black">{value}</span>
          <span className="block text-xs md:text-base text-lumepic-light_black font-lumepicRegular">
            {t(label)}
          </span>
        </div>
      </div>

      {showDetailsButton && (
        <div
          className="absolute bottom-2 right-2 flex items-center gap-1 hover:cursor-pointer hover:text-lumepic-success_dark_green transition-colors duration-200 ease-in-out"
          onClick={onDetailsClick}
        >
          <FontAwesomeIcon icon={faCirclePlus} className="text-primary hidden md:flex" />
          <Button
            btnType="TertiaryAction"
            onClick={() => onDetailsClick?.()}
            extraStyle="text-xs md:text-sm font-medium"
          >
            {t('Details')}
          </Button>
        </div>
      )}
    </div>
  )
}

export default MetricCard
