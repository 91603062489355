import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import UploadImageToAlbumStore, { ProgressInfo } from './UploadImageToAlbumStore'
import StoresContext from '../../../../../providers/storesContext'
import 'moment/locale/es'
import Album from '../../../../../shared/models/Album'
import withErrorHandler from '../../../../../hoc/withErrorHandler/withErrorHandler'
import axios from '../../../../../services/axios'
import ImageUploadTable from './ImageUploadProgress/ImageUploadTable'
import CancelAlbumUploadModal from './components/CancelAlbumUploadModal'
import UploadImagesActions from './components/UploadImagesActions'
import AlbumImageUploader from 'components/UI/AlbumImageUploader'
import Breadcrumb from 'components/UI/Breadcrumb'
import { isNil } from 'lodash'
import { Event } from 'shared/models/Event'
import { FifteenMegabytesInBytes } from 'shared/util/constants'

type UploadImageToAlbumProps = {
  album: Album
  handleFinish: (album: Album) => void
  isCreating: boolean
  closeModal: () => void
  albumEvent: Event | undefined
  setImagesUploaded: () => void
}

const UploadImageToAlbum = ({
  album,
  handleFinish,
  isCreating,
  closeModal,
  albumEvent,
  setImagesUploaded,
}: UploadImageToAlbumProps) => {
  const { authStore } = useContext(StoresContext)!
  const { t } = useTranslation()
  const [uploadImageToAlbumStore] = useState(() => {
    return new UploadImageToAlbumStore(authStore)
  })

  const initialRender = useRef(true)
  const imagesUploaded = useRef(false)

  const [isOnline, setIsOnline] = useState(navigator.onLine)
  const handleOnLine = () => setIsOnline(true)
  const handleOffLine = () => setIsOnline(false)

  const [visibleConfirmModal, setVisibleConfirmModal] = useState(false)
  const hideConfirmModal = () => setVisibleConfirmModal(false)

  useEffect(() => {
    window.addEventListener('online', handleOnLine)
    window.addEventListener('offline', handleOffLine)

    return () => {
      window.removeEventListener('online', handleOnLine)
      window.removeEventListener('offline', handleOffLine)
    }
  }, [])

  const auxSetProgressInfos = useCallback((pginfos: ProgressInfo[]) => {
    const pginfosDisplay = [...uploadImageToAlbumStore.progressInfos, ...pginfos]
    uploadImageToAlbumStore.changeProgressInfos(pginfosDisplay)
  }, [])

  const reset = () => {
    uploadImageToAlbumStore.reset()
    imagesUploaded.current = false
    initialRender.current = true
  }

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false
    } else if (!imagesUploaded.current && uploadImageToAlbumStore.images.length > 0) {
      imagesUploaded.current = true
    }
  }, [auxSetProgressInfos, uploadImageToAlbumStore.images])

  const maxFilesAllowedToUpload = !isNil(album.event.id)
    ? uploadImageToAlbumStore.eventAlbumPhotographsLimit
    : uploadImageToAlbumStore.independentAlbumPhotographsLimit

  const handleConfirm = () => {
    hideConfirmModal()
    reset()
    uploadImageToAlbumStore.cancelBatchUpload()
    closeModal()
  }

  const onSubmitImages = (images: File[]) => {
    const imagesUnderFifteenMegabytes = images.filter(
      (image) => image.size < FifteenMegabytesInBytes
    )

    if (imagesUnderFifteenMegabytes.length === 0) {
      uploadImageToAlbumStore.changeHasUploadedOversizedImages(true)
      return
    }

    if (imagesUnderFifteenMegabytes.length < images.length) {
      uploadImageToAlbumStore.changeHasUploadedOversizedImages(true)
    }
    const imagesToDisplay = [...uploadImageToAlbumStore.images, ...imagesUnderFifteenMegabytes]
    uploadImageToAlbumStore.changeImages(imagesToDisplay)
    const progressInfos = imagesUnderFifteenMegabytes.map((image) => {
      return {
        percentage: 0,
        fileName: image.name,
        requestSent: false,
        error: false,
        size: image.size,
        type: image.type,
      }
    })
    auxSetProgressInfos(progressInfos)
    setImagesUploaded()
  }

  const handleFileInput = (files: File[]) => {
    const images = Array.from(files)
    onSubmitImages(images)
  }

  let displayView
  if (uploadImageToAlbumStore.progressInfos.length === 0) {
    displayView = (
      <AlbumImageUploader
        onUpload={onSubmitImages}
        store={uploadImageToAlbumStore}
        album={album}
        maxFilesAllowedToUpload={maxFilesAllowedToUpload}
      />
    )
  } else if (uploadImageToAlbumStore.progressInfos.length > 0) {
    displayView = <ImageUploadTable store={uploadImageToAlbumStore} isOnline={isOnline} />
  }

  return (
    <>
      <div className="flex flex-col px-2 pt-2">
        {isCreating && (
          <div className="sm:hidden mt-3 flex justify-start ml-2">
            <Breadcrumb
              completedSteps={!isNil(albumEvent) && !isNil(albumEvent.quantityDiscount) ? 3 : 4}
              totalSteps={!isNil(albumEvent) && !isNil(albumEvent.quantityDiscount) ? 4 : 5}
            />
          </div>
        )}
        <h3 className="font-semibold my-3 sm:my-1 text-xl">{t('Upload Images to Album')}</h3>
        <div className="w-full h-full max-h-full overflow-hidden">{displayView}</div>
        <p className="text-sm text-lumepic-light_black">
          {t('Maximum photographs allowed to be uploaded')}: {maxFilesAllowedToUpload}
        </p>
        <UploadImagesActions
          isCreating={isCreating}
          store={uploadImageToAlbumStore}
          album={album}
          handleFinish={handleFinish}
          handleFileInput={handleFileInput}
          albumEvent={albumEvent}
        />
      </div>
      <CancelAlbumUploadModal
        open={visibleConfirmModal}
        handleConfirm={handleConfirm}
        hideConfirmModal={hideConfirmModal}
      />
    </>
  )
}

export default withErrorHandler(observer(UploadImageToAlbum), axios)
