import React, { useMemo, useState } from 'react'
import { BarChart } from '@mui/x-charts'
import { useTranslation } from 'react-i18next'
import { axisClasses } from '@mui/x-charts/ChartsAxis'
import { Button, Tooltip } from '@mui/material'
import { format, parse, subMonths, addMonths, startOfMonth, endOfMonth, isBefore } from 'date-fns'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import Toggle, { DisplayMode } from 'components/UI/Toggle/Toggle'
import { isMobileDevice } from 'shared/utility'

type SalesBarChartProps = {
  salesInsights: { year: number; month: number; salesCount: number; salesAmountInUsd: number }[]
}

const SalesBarChart = ({ salesInsights }: SalesBarChartProps) => {
  const { t } = useTranslation()

  const [barChartDisplayMode, setBarChartDisplayMode] = useState<DisplayMode>(DisplayMode.USD)

  const [currentDateRange, setCurrentDateRange] = useState(() => {
    const end = endOfMonth(new Date())
    const start = startOfMonth(subMonths(end, 11))
    return { start, end }
  })

  const orderedSalesInsights = useMemo(() => {
    return salesInsights.sort((a, b) => {
      const dateA = parse(`${a.year}-${a.month}`, 'yyyy-MM', new Date())
      const dateB = parse(`${b.year}-${b.month}`, 'yyyy-MM', new Date())
      return dateA.getTime() - dateB.getTime()
    })
  }, [salesInsights])

  const firstSaleDate = useMemo(() => {
    if (orderedSalesInsights.length === 0) return null
    return parse(
      `${orderedSalesInsights[0].year}-${orderedSalesInsights[0].month}`,
      'yyyy-MM',
      new Date()
    )
  }, [orderedSalesInsights])

  const onMoveBackward = () => {
    setCurrentDateRange((prev) => {
      const newStart = subMonths(prev.start, 1)
      const newEnd = subMonths(prev.end, 1)
      if (firstSaleDate && isBefore(newStart, firstSaleDate)) {
        const adjustedStart = startOfMonth(firstSaleDate)
        return {
          start: adjustedStart,
          end: endOfMonth(addMonths(adjustedStart, 11)),
        }
      }
      return { start: newStart, end: newEnd }
    })
  }

  const onMoveForward = () => {
    setCurrentDateRange((prev) => {
      const newEnd = addMonths(prev.end, 1)
      const today = new Date()
      return {
        start: addMonths(prev.start, 1),
        end: newEnd > today ? endOfMonth(today) : newEnd,
      }
    })
  }

  const canMoveBackward = useMemo(() => {
    return firstSaleDate && isBefore(firstSaleDate, currentDateRange.start)
  }, [firstSaleDate, currentDateRange.start])

  const canMoveForward = useMemo(() => {
    return isBefore(currentDateRange.end, endOfMonth(new Date()))
  }, [currentDateRange.end])

  const monthLabels = Array.from({ length: 12 }, (_, i) => {
    const date = addMonths(currentDateRange.start, i)
    const monthName = t(`months.${format(date, 'MMM').toLowerCase()}`)
    return `${monthName}-${format(date, 'yy')}`
  })

  const filteredSalesData = useMemo(() => {
    return orderedSalesInsights
      .filter((insight) => {
        const insightDate = parse(`${insight.year}-${insight.month}`, 'yyyy-MM', new Date())
        return insightDate >= currentDateRange.start && insightDate <= currentDateRange.end
      })
      .map((insight) => ({
        date: parse(`${insight.year}-${insight.month}`, 'yyyy-MM', new Date()),
        value:
          barChartDisplayMode === DisplayMode.USD
            ? parseFloat(insight.salesAmountInUsd.toFixed(2))
            : insight.salesCount,
      }))
  }, [orderedSalesInsights, currentDateRange, barChartDisplayMode])

  const salesData = useMemo(() => {
    return monthLabels.map((label) => {
      const date = parse(label, 'MMM-yy', new Date())
      const matchingData = filteredSalesData.find(
        (item) =>
          item.date.getMonth() === date.getMonth() && item.date.getFullYear() === date.getFullYear()
      )
      return matchingData ? parseFloat(matchingData.value.toFixed(2)) : 0
    })
  }, [monthLabels, filteredSalesData])

  const yAxisLabel = barChartDisplayMode === DisplayMode.USD ? t('Sales in USD') : t('Sales count')

  return (
    <>
      <h2 className="text-center flex font-semibold text-base text-lumepic-light_black">
        {t('Monthly Sales')}
      </h2>
      <div className="justify-center flex">
        <Toggle displayMode={barChartDisplayMode} setDisplayMode={setBarChartDisplayMode} />
      </div>
      <div className="w-full h-[calc(100%-2rem)] flex items-center justify-between">
        <Tooltip title={t('Backward')} arrow>
          <span className="w-4 flex justify-center">
            <Button
              onClick={onMoveBackward}
              disabled={!canMoveBackward}
              sx={{
                height: '50px',
                padding: 0,
                borderRadius: '100%',
                color: '#4A4A4A',
                '&.Mui-disabled': {
                  color: 'grey',
                },
              }}
            >
              <ChevronLeft />
            </Button>
          </span>
        </Tooltip>
        <div className="flex-grow max-h-96 md:h-full flex items-center justify-center">
          <BarChart
            height={isMobileDevice() ? 250 : 350}
            xAxis={[{ scaleType: 'band', data: monthLabels }]}
            yAxis={[
              {
                label: yAxisLabel,
                labelStyle: {
                  display: 'flex',
                  color: '#4A4A4A',
                },
              },
            ]}
            series={[
              {
                data: salesData,
                color: '#5AB274',
                highlightScope: { faded: 'global', highlighted: 'item' },
              },
            ]}
            sx={{
              [`.${axisClasses.left} .${axisClasses.label}`]: {
                transform: 'translate(-12px, 0)',
              },
              [`.${axisClasses.root}`]: {
                [`.${axisClasses.tick}, .${axisClasses.line}`]: {
                  stroke: 'none',
                },
                [`.${axisClasses.tickLabel}`]: {
                  fill: '#4A4A4A',
                },
              },
              '& .MuiChartsAxis-left .MuiChartsAxis-tickLabel': {
                paddingLeft: '4px',
              },
            }}
          />
        </div>
        <Tooltip title={t('Forward')} arrow>
          <span className="w-4 flex justify-center">
            <Button
              onClick={onMoveForward}
              disabled={!canMoveForward}
              sx={{
                height: '50px',
                padding: 0,
                borderRadius: '50%',
                color: '#4A4A4A',
                '&.Mui-disabled': {
                  color: 'grey',
                },
              }}
            >
              <ChevronRight />
            </Button>
          </span>
        </Tooltip>
      </div>
    </>
  )
}

export default SalesBarChart
