import React from 'react'
import { observer } from 'mobx-react'
import Cart, { CartLine } from '../../../../../../shared/models/Cart'
import { useTranslation } from 'react-i18next'
import Spinner from 'components/UI/Spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

type DiscountsListProps = {
  cart: Cart
  lines: CartLine[]
  isLoadingDiscounts: boolean
}

const DiscountsList = ({ cart, lines, isLoadingDiscounts }: DiscountsListProps) => {
  const { t } = useTranslation()

  if (isLoadingDiscounts) {
    return (
      <div className="flex justify-between">
        <p className="mb-0 font-medium">{t('Discounts')}</p>
        <Spinner noStyles size={15} color="info" />
      </div>
    )
  }

  return (
    <>
      {cart.getTotalDiscountsAmountByGroupLine(lines) > 0 && (
        <div>
          <div className="flex justify-between">
            <p className="text-lumepic-light_black font-medium">{t('Discounts')}</p>
          </div>

          {cart.getQuantityDiscountAmountByGroupLine(lines) > 0 && (
            <div className="flex justify-between items-center mt-1">
              <div className="flex gap-1">
                <FontAwesomeIcon
                  icon={faCheck}
                  className="text-lumepic-success_dark_green font-light"
                />
                <p className="text-xs font-light text-lumepic-success_dark_green">
                  {t('Quantity discounts')}
                </p>
              </div>
              <p className="text-xs font-light text-lumepic-grey">
                {cart.currency?.toUpperCase() || 'UYU'}{' '}
                {cart.getQuantityDiscountAmountByGroupLine(lines).toFixed(2)}
              </p>
            </div>
          )}

          {cart.getCodeDiscountsAmountByGroupLine(lines) > 0 && (
            <>
              {cart.getCodeDiscounts().map((codeDiscount, i) => (
                <div className="flex justify-between items-center mt-1" key={`discount_${i}`}>
                  <div className="flex gap-1">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="text-lumepic-success_dark_green font-light"
                    />
                    <p className="text-xs font-light text-lumepic-success_dark_green">
                      {t('Code')}:
                    </p>
                    <p className="mb-0 font-light text-lumepic-success_dark_green text-xs">
                      {codeDiscount.code}
                    </p>
                  </div>

                  <p className="mb-0 text-xs font-light text-lumepic-grey">
                    {cart.currency?.toUpperCase() || 'UYU'}{' '}
                    {cart.getCodeDiscountsAmountByGroupLine(lines).toFixed(2)}
                  </p>
                </div>
              ))}
            </>
          )}

          {cart.getPackagesDiscountAmountByGroupLine(lines) > 0 && (
            <div className="flex justify-between items-center mt-1">
              <div className="flex gap-1">
                <FontAwesomeIcon
                  icon={faCheck}
                  className="text-lumepic-success_dark_green font-light"
                />
                <p className="text-xs font-light text-lumepic-success_dark_green">
                  {t('Package discounts')}
                </p>
              </div>
              <p className="mb-0 text-xs font-light text-lumepic-grey">
                {cart.currency?.toUpperCase() || 'UYU'}{' '}
                {cart.getPackagesDiscountAmountByGroupLine(lines).toFixed(2)}
              </p>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default observer(DiscountsList)
