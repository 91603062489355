import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import PhotographerMetrics from './Metrics/PhotographerMetrics'
import storesContext from 'providers/storesContext'
import { isNil } from 'lodash'
import MyAccountDashboardStore from './stores/MyAccountDashboardStore'
import PhotographerSales from './SalesInsights/PhotographerSales'
import PhotographerAlbumsInsights from './SalesInsights/PhotographerAlbumsInsights'
import PhotographerAlbumsData from './AlbumInsights/PhotographerAlbumsData'
import { AlbumSearchUrlQueryParams, Filters } from 'components/UI/SearchBar/SearchBar'
import { useQuery } from 'hooks/useQuery'
import { parseDate } from 'shared/utility'
import { getLocalDate } from 'shared/util/dates'
import SearchBarStore from 'components/UI/SearchBar/SearchBarStore'
import { FeedOrderFields, FeedOrderQueryParams, FeedOrderSorts } from 'services/FeedService'
import Spinner from 'components/UI/Spinner'

const PhotographerDashboard = () => {
  const MyAccountDashboardFilters = [
    Filters.Country,
    Filters.Location,
    Filters.Activity,
    Filters.Event,
    Filters.DateRange,
  ]

  const { authStore, locationStore, activityStore, eventStore } = useContext(storesContext)!

  const query = useQuery()
  const locationId = query.get(AlbumSearchUrlQueryParams.LocationId) || undefined
  const activityId = query.get(AlbumSearchUrlQueryParams.ActivityId) || undefined
  const eventId = query.get(AlbumSearchUrlQueryParams.EventId) || undefined

  const dateFrom = parseDate(query.get(AlbumSearchUrlQueryParams.DateFrom))
  const dateTo = parseDate(query.get(AlbumSearchUrlQueryParams.DateTo))

  const apiDateFrom = getLocalDate(dateFrom)
  const apiDateTo = getLocalDate(dateTo)

  if (!isNil(apiDateTo)) {
    apiDateTo.setUTCHours(23, 59, 59, 999)
  }

  const dateRange: [Date | null, Date | null] = [apiDateFrom, apiDateTo]
  const [searchBarStore] = useState(
    () =>
      new SearchBarStore(eventStore, activityStore, locationStore, {
        locationId,
        activityId,
        eventId,
        dateRange,
      })
  )

  const [store] = useState(() => new MyAccountDashboardStore(authStore, searchBarStore))

  const {
    photographerMetrics,
    salesInsights,
    publishedAlbums,
    independentAlbumSales,
    eventAlbumSales,
    salesByActivity,
    albums,
    albumCount,
    albumsPerPage,
    page,
    isLoading,
  } = store

  const [sortOrder, setSortOrder] = useState<FeedOrderQueryParams>({
    field: FeedOrderFields.CREATED_AT,
    sort: FeedOrderSorts.DESC,
  })

  useEffect(() => {
    store.getPhotographerMetrics()
    store.getPhotographerSalesInsights()
    store.getPhotographerPublishedAlbums()
    store.getPhotographerSalesByType()
    store.getPhotographerSalesByActivity()
  }, [])

  useEffect(() => {
    store.getPhotographerAlbums(sortOrder)
  }, [sortOrder])

  const handleSearch = () => {
    store.getPhotographerMetrics()
  }

  const setRowsPerPage = (albumsPerPage: number) => {
    store.setAlbumsPerPage(albumsPerPage)
  }

  const handleChangePage = (e, newPage: number) => {
    store.handleChangePage(e, newPage)
  }

  //TODO: pass this to skeleton
  if (isLoading) {
    return <Spinner />
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="w-full">
        {!isLoading && !isNil(photographerMetrics) && (
          <PhotographerMetrics
            metrics={photographerMetrics}
            searchBarStore={searchBarStore}
            handleSearch={handleSearch}
            filters={MyAccountDashboardFilters}
          />
        )}
      </div>
      <div className="w-full bg-white border-t-4 border-primary">
        {!isLoading && !isNil(albums) && (
          <PhotographerAlbumsData
            albums={albums}
            albumCount={albumCount}
            albumsPerPage={albumsPerPage}
            page={page}
            setRowsPerPage={setRowsPerPage}
            handleChangePage={handleChangePage}
            onSortChange={setSortOrder}
            sortOrder={sortOrder}
          />
        )}
      </div>

      <div className="w-full bg-white border-t-4 border-primary">
        {!isLoading && !isNil(salesInsights) && (
          <>
            <PhotographerSales
              salesInsights={salesInsights}
              independentAlbumSales={independentAlbumSales}
              eventAlbumsSales={eventAlbumSales}
            />
          </>
        )}
        {!isLoading && !isNil(publishedAlbums) && !isNil(salesByActivity) && (
          <PhotographerAlbumsInsights
            publishedAlbums={publishedAlbums}
            salesByActivity={salesByActivity}
          />
        )}
      </div>
    </div>
  )
}

export default observer(PhotographerDashboard)
