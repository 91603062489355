import React, { useContext, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useHistory, useLocation } from 'react-router-dom'
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler'
import StoresContext from '../../providers/storesContext'
import AboutSection from './components/AboutSection'
import ToDosSection from './components/ToDosSection'
import axios from '../../services/axios'
import PhotographerDiscountCode from './components/PersonalDiscountCode/PhotographerDiscountCode'
import { toast } from 'react-toastify'
import Spinner from 'components/UI/Spinner'
import { useTranslation } from 'react-i18next'
import { FeatureFlags } from 'config/constants/featureFlags'
import { isNil } from 'lodash'
import PhotographerDiscountsSection from './components/PhotographerDiscountSection/PhotographerDiscountsSection'
import { Alert } from '@mui/material'
import Button from 'components/UI/Button'
import { SetPageTitle, getFeatureFlagValue } from '../../shared/utility'
import PhotographerDashboard from './components/MyAccountDashboard/PhotographerDashboard'
import PhotographerClients from './components/PhotographerClients'

const MyAccount = () => {
  SetPageTitle('My Account')
  const { t } = useTranslation()
  const { authStore, featureFlagsStore } = useContext(StoresContext)!
  const location = useLocation()
  const history = useHistory()
  const loggedUser = authStore.getLoggedUser()

  const codeDiscountsEnabled = getFeatureFlagValue(
    featureFlagsStore,
    FeatureFlags.CODE_DISCOUNTS_CREATION,
    false
  )

  const enableStripeOnboarding = getFeatureFlagValue(
    featureFlagsStore,
    FeatureFlags.ENABLE_STRIPE_ONBOARDING,
    true
  )

  useEffect(() => {
    authStore.getUser()
  }, [])

  const requestEmailVerification = () => {
    authStore.requestEmailVerification()
    toast.info(t('Please access your email to verify your account'), {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    })
  }

  const requestPhotographerRole = () => {
    authStore.requestPhotographerRole()
    requestEmailVerification()
  }

  const redirectToStripeOnboarding = () => {
    authStore.getOnboardingUrl().then((accountUrl) => {
      if (!accountUrl) {
        toast.error(t('Payment provider URL not found. Please try again later.'), {
          position: 'top-right',
          hideProgressBar: true,
          autoClose: 3000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      } else {
        window.location.href = accountUrl
      }
    })
  }

  const redirectToStripeLogin = () => {
    authStore.getLoginUrl().then((loginUrl) => {
      if (!isNil(loginUrl)) {
        window.location.href = loginUrl
      }
    })
  }

  const requestMercadoPagoCredentials = (code: string) => {
    authStore.requestMercadopagoCredentials(code).then((result) => {
      if (result) {
        const queryParams = new URLSearchParams(location.search)
        if (queryParams.has('code') && queryParams.has('state')) {
          queryParams.delete('code')
          queryParams.delete('state')
          history.replace({
            search: queryParams.toString(),
          })
        }
      }
    })
  }

  const afterSaveFidelityCodeDiscount = () => {
    toast.success(t('Fidelity code discount created successfully!'), {
      position: 'top-right',
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    authStore.getUser()
  }

  useEffect(() => {
    const code = new URLSearchParams(location.search).get('code')
    const state = new URLSearchParams(location.search).get('state')
    if (code && state && !loggedUser.mercadoPagoVerified) {
      requestMercadoPagoCredentials(code)
    }
  }, [])

  if (
    authStore.isLoading ||
    (enableStripeOnboarding && authStore.isLoadingRequestPhotographerRole)
  ) {
    return (
      <Spinner divStyles="flex justify-center bg-bg_details h-primaryMobile md:h-primaryDesktop items-center" />
    )
  }
  if (enableStripeOnboarding && authStore.isLoadingOnboardingRedirection) {
    return (
      <div className="flex flex-col items-center justify-center w-full bg-bg_details h-primaryMobile md:h-primaryDesktop align-center">
        <span className="text-base text-center">
          {t(
            'We are redirecting you to our payment provider, Stripe, to select the bank account you want to receive the funds into...'
          )}
        </span>
        <Spinner divStyles="pt-2" />
      </div>
    )
  }

  return (
    <>
      <div className="min-h-screen bg-bg_details">
        <div className="container py-3 mx-auto">
          <div className="md:flex no-wrap md:-mx-2 ">
            <div className="flex flex-col w-full">
              <div className="my-4 md:mx-2">
                <AboutSection user={loggedUser} requestPhotographerRole={requestPhotographerRole} />
              </div>
              <div>
                <div className="w-full md:mx-2 md:flex">
                  <div className="flex-col md:w-full md:mr-4 md:flex">
                    {loggedUser.isPhotographer && (
                      <>
                        {loggedUser.mercadoPagoVerified && !loggedUser.stripeAccountId && (
                          <div className="pb-2">
                            <Alert severity="warning" className="w-full flex justify-center">
                              {t(
                                'Mercado Pago is no longer our payment provider! You need to link your account with Stripe, otherwise you’ll not be able to sell'
                              )}
                              .
                              <div className="w-full flex justify-center mt-2">
                                <Button
                                  btnType="SecondaryAction"
                                  onClick={() => redirectToStripeOnboarding()}
                                  extraStyle="text-sm mr-6"
                                >
                                  {t('Sync to Stripe')}
                                </Button>
                              </div>
                            </Alert>
                          </div>
                        )}

                        <div className="md:flex">
                          <ToDosSection
                            user={loggedUser}
                            verifyEmail={requestEmailVerification}
                            redirectToStripeLogin={redirectToStripeLogin}
                            redirectToStripeOnboarding={redirectToStripeOnboarding}
                          />
                          <div className="my-4" />
                          <PhotographerDiscountCode
                            afterSaveDiscount={afterSaveFidelityCodeDiscount}
                          />
                        </div>
                        <div className="mt-5">
                          <PhotographerDashboard />
                        </div>
                        {!isNil(loggedUser.clients) && loggedUser.clients.length > 0 && (
                          <PhotographerClients />
                        )}
                      </>
                    )}
                    <div>
                      {codeDiscountsEnabled && loggedUser.isPhotographer && (
                        <PhotographerDiscountsSection />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default withErrorHandler(observer(MyAccount), axios)
