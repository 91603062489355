export enum AlbumTableColumnId {
  Location = 'location',
  Date = 'date',
  Activity = 'activity',
  Description = 'description',
  Actions = 'actions',
  Status = 'status',
  PhotographCount = 'photographCount',
  Views = 'views',
  SalesCount = 'salesCount',
  NetSalesAmountInUsd = 'netSalesAmountInUsd',
  SoldPhotographsCount = 'soldPhotographsCount',
  Ratio = 'ratio',
}

export interface AlbumTableColumn {
  id: AlbumTableColumnId
  label: string
  minWidth?: number
  format?: any
}
