import { makeAutoObservable, runInAction } from 'mobx'
import AuthStore from 'stores/AuthStore'
import AlbumService from '../../../../../services/AlbumService'
import InputStore from '../../../../../shared/store/InputStore'
import Album from '../../../../../shared/models/Album'
import { isNil } from 'lodash'
import FileService from 'services/FileService'
import ConfigService, { ConfigKeys } from 'config'

const UPLOAD_BLOCK_SIZE = 6
const MAX_PHOTOGRAPHS_TO_UPLOAD_FOR_EVENT_ALBUM = parseInt(
  ConfigService.getValue(ConfigKeys.EVENT_ALBUM_PHOTOGRAPHS_LIMIT)
)
const MAX_PHOTOGRAPHS_TO_UPLOAD_FOR_INDEPENDENT_ALBUM = parseInt(
  ConfigService.getValue(ConfigKeys.INDEPENDENT_ALBUM_PHOTOGRAPHS_LIMIT)
)

export type ProgressInfo = {
  fileName: string
  percentage: number
  requestSent: boolean
  error: boolean
  size: number
  type: string
  uploadUrl?: string
  uploadKey?: string
  retryCount?: number
}

class UploadImageToAlbumStore {
  public isLoading: boolean
  public images: File[]
  public imagesSubmitted: boolean
  public progressInfos: ProgressInfo[]
  public uploadingImage: boolean
  public lastUploadedImageIndex: number | null
  public shouldInterrupt: boolean
  public error: any
  public name: InputStore<string>
  public serverError: string
  private albumService: AlbumService
  private fileService: FileService
  public waitingForConnection = false
  public hasImagesWithoutMetadata: boolean
  public hasUploadedOversizedImages: boolean
  private retryQueue: ProgressInfo[]
  public exceededRetries: ProgressInfo[]
  public eventAlbumPhotographsLimit: number
  public independentAlbumPhotographsLimit: number
  public maxPhotographs: number
  constructor(private readonly authStore: AuthStore) {
    this.reset()
    makeAutoObservable(this)
    this.albumService = new AlbumService()
    this.fileService = new FileService()
    this.retryQueue = []
    this.eventAlbumPhotographsLimit = MAX_PHOTOGRAPHS_TO_UPLOAD_FOR_EVENT_ALBUM
    this.independentAlbumPhotographsLimit = MAX_PHOTOGRAPHS_TO_UPLOAD_FOR_INDEPENDENT_ALBUM
  }

  reset() {
    this.isLoading = false
    this.images = []
    this.imagesSubmitted = false
    this.progressInfos = []
    this.uploadingImage = false
    this.lastUploadedImageIndex = null
    this.error = false
    this.hasImagesWithoutMetadata = false
    this.hasUploadedOversizedImages = false
    this.retryQueue = []
    this.exceededRetries = []
    this.eventAlbumPhotographsLimit = MAX_PHOTOGRAPHS_TO_UPLOAD_FOR_EVENT_ALBUM
    this.independentAlbumPhotographsLimit = MAX_PHOTOGRAPHS_TO_UPLOAD_FOR_INDEPENDENT_ALBUM
  }

  changeName(val: string) {
    this.name.setValue(val)
  }

  clearErrors() {
    this.name.clearError()
  }

  cancelBatchUpload(): void {
    this.shouldInterrupt = true
  }

  changeProgressInfos(val: ProgressInfo[]) {
    this.progressInfos = val
  }

  changeImagesSubmitted(val: boolean) {
    this.imagesSubmitted = val
  }

  changeImages(val: File[]) {
    this.images = val
  }

  changeHasImagesWithoutMetadata(val: boolean) {
    this.hasImagesWithoutMetadata = val
  }

  changeHasUploadedOversizedImages(val: boolean) {
    this.hasUploadedOversizedImages = val
  }

  uploadImageToAlbumStart() {
    this.error = null
    this.uploadingImage = true
  }

  uploadImageToAlbumFail(uploadedImageIndex: number, uploadUrl?: string, uploadKey?: string) {
    const _progressInfos = [...this.progressInfos]
    _progressInfos[uploadedImageIndex].error = true
    _progressInfos[uploadedImageIndex].percentage = 100
    _progressInfos[uploadedImageIndex].uploadUrl = uploadUrl
    _progressInfos[uploadedImageIndex].uploadKey = uploadKey
    _progressInfos[uploadedImageIndex].retryCount =
      (_progressInfos[uploadedImageIndex].retryCount || 0) + 1
    this.retryQueue.push(_progressInfos[uploadedImageIndex])

    this.changeProgressInfos(_progressInfos)
    this.error = null
    this.uploadingImage = false
    this.lastUploadedImageIndex = uploadedImageIndex
    if (this.allImagesUploaded()) {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  allImagesUploaded() {
    return (
      this.imagesSubmitted &&
      this.progressInfos &&
      this.progressInfos.filter((pi) => {
        return pi.percentage !== 100
      }).length === 0
    )
  }

  private buildUploadBlocks(): File[][] {
    const numberOfBlocks = Math.ceil(this.images.length / UPLOAD_BLOCK_SIZE)

    const blocks = Array(numberOfBlocks)
    for (let i = 0; i < this.images.length; i += UPLOAD_BLOCK_SIZE) {
      const chunk = this.images.slice(i, i + UPLOAD_BLOCK_SIZE)
      const blockIndex = Math.floor(i / UPLOAD_BLOCK_SIZE)
      blocks[blockIndex] = chunk
    }
    return blocks
  }

  async batchUpload(album: Album): Promise<void> {
    runInAction(() => {
      this.isLoading = true
      this.shouldInterrupt = false
    })
    this.uploadImageToAlbumStart()
    const blocks = this.buildUploadBlocks()

    const promises: Promise<string>[] = []
    for (const block of blocks) {
      if (this.shouldInterrupt) {
        runInAction(() => {
          this.shouldInterrupt = false
        })
        return
      }
      try {
        const response = await this.albumService.batchCreatePhotographs(
          block,
          album.id,
          this.authStore.getToken()
        )
        if (response.hasImagesWithoutMetadata) {
          runInAction(() => {
            this.hasImagesWithoutMetadata = true
          })
        }
        for (const image of block) {
          if (this.shouldInterrupt) {
            runInAction(() => {
              this.shouldInterrupt = false
            })
            return
          }
          const index = this.images.indexOf(image)
          const uploadInfo = response.data.find(
            (info) => info.photograph.originalFileName === image.name
          )
          if (!isNil(uploadInfo)) {
            if (this.shouldInterrupt) {
              runInAction(() => {
                this.shouldInterrupt = false
              })
              return
            }
            try {
              promises.push(
                this.fileService.uploadFileToUrl(
                  image,
                  uploadInfo.uploadUrl,
                  uploadInfo.uploadKey,
                  (event: any) => {
                    const _progressInfos = [...this.progressInfos]
                    _progressInfos[index].percentage = Math.round(
                      (100 * event.loaded) / event.total
                    )
                    _progressInfos[index].requestSent = true
                    _progressInfos[index].error = false
                    this.changeProgressInfos(_progressInfos)
                  },
                  () => {
                    this.uploadImageToAlbumFail(index, uploadInfo.uploadUrl, uploadInfo.uploadKey)
                  }
                )
              )
            } catch (e: any) {
              runInAction(() => {
                this.uploadImageToAlbumFail(index, uploadInfo.uploadUrl, uploadInfo.uploadKey)
                const displayedError = this.parseRequestErrors(e.response?.data?.errors || {})

                if (!displayedError) {
                  this.serverError =
                    'Something went wrong, please check the provided data and try again.'
                }
              })
            }
          }
        }
      } catch (e: any) {
        runInAction(() => {
          const displayedError = this.parseRequestErrors(e.response?.data?.errors || {})

          if (!displayedError) {
            this.serverError = 'Something went wrong, please check the provided data and try again.'
          }
        })
      }
    }
    await Promise.all(promises)
    await this.retryFailedUploads(false)
  }

  async retryFailedUploads(manualRetry: boolean) {
    runInAction(() => {
      if (manualRetry) {
        this.isLoading = true
      }
    })
    const promises: Promise<string>[] = []
    const queue = manualRetry ? this.exceededRetries : this.retryQueue
    const retriesNumber = manualRetry ? 3 : 2
    while (queue.length > 0) {
      const uploadInfo = queue.shift()!
      if (uploadInfo.retryCount! > retriesNumber) {
        if (!manualRetry) {
          this.exceededRetries.push(uploadInfo)
        }
        continue
      }
      const image = this.images.find((img) => img.name === uploadInfo.fileName)
      if (image) {
        const index = this.images.indexOf(image)
        try {
          promises.push(
            this.fileService.uploadFileToUrl(
              image,
              uploadInfo.uploadUrl!,
              uploadInfo.uploadKey!,
              (event: any) => {
                const _progressInfos = [...this.progressInfos]
                _progressInfos[index].percentage = Math.round((100 * event.loaded) / event.total)
                _progressInfos[index].requestSent = true
                _progressInfos[index].error = false
                this.changeProgressInfos(_progressInfos)
              },
              () => {
                this.uploadImageToAlbumFail(index, uploadInfo.uploadUrl, uploadInfo.uploadKey)
              }
            )
          )
        } catch (e: any) {
          runInAction(() => {
            this.uploadImageToAlbumFail(index, uploadInfo.uploadUrl, uploadInfo.uploadKey)
            const displayedError = this.parseRequestErrors(e.response?.data?.errors || {})

            if (!displayedError) {
              this.serverError =
                'Something went wrong, please check the provided data and try again.'
            }
          })
        }
      }
    }
    await Promise.all(promises)
    this.isLoading = false
  }

  findFilesWithLessThanThreeRetries(): number {
    const progressInfos = this.exceededRetries.filter((pi) => {
      return !isNil(pi.retryCount) && pi.retryCount < 4
    })
    return progressInfos.length
  }

  async retrySingleUpload(progressInfo: ProgressInfo) {
    const image = this.images.find((img) => img.name === progressInfo.fileName)
    if (image) {
      const index = this.images.indexOf(image)
      try {
        await this.fileService.uploadFileToUrl(
          image,
          progressInfo.uploadUrl!,
          progressInfo.uploadKey!,
          (event: any) => {
            const _progressInfos = [...this.progressInfos]
            _progressInfos[index].percentage = Math.round((100 * event.loaded) / event.total)
            _progressInfos[index].requestSent = true
            _progressInfos[index].error = false
            this.changeProgressInfos(_progressInfos)
          },
          () => {
            this.uploadImageToAlbumFail(index, progressInfo.uploadUrl, progressInfo.uploadKey)
          }
        )
        runInAction(() => {
          const removeIndex = this.exceededRetries.indexOf(progressInfo)
          if (removeIndex > -1) {
            this.exceededRetries.splice(removeIndex, 1)
          }
        })
      } catch (e: any) {
        runInAction(() => {
          this.uploadImageToAlbumFail(index, progressInfo.uploadUrl, progressInfo.uploadKey)
          const displayedError = this.parseRequestErrors(e.response?.data?.errors || {})

          if (!displayedError) {
            this.serverError = 'Something went wrong, please check the provided data and try again.'
          }
        })
      }
    }
  }

  parseRequestErrors(messages: any) {
    const keys = Object.keys(messages)
    let displayedError = false

    keys.forEach((key) => {
      const [error] = messages[key]

      switch (key) {
        case 'name':
          this.name.setError(true, error)
          displayedError = true
          break

        default:
          break
      }
    })

    return displayedError
  }

  getCurrentUploadingImageIndex(): number {
    let lastUploaded = 0
    for (let i = 0; i < this.progressInfos.length; i++) {
      if (this.progressInfos[i].percentage === 100) {
        lastUploaded = i
      }
    }
    return lastUploaded
  }
}

export default UploadImageToAlbumStore
