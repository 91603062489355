import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import { albumDetails, myAlbumAlbumDetails } from '../../../../services/Paths'
import { useTranslation } from 'react-i18next'
import { formatUtcToLocaleDate, isMobileDevice } from '../../../../shared/utility'
import Album from '../../../../shared/models/Album'
import { TableCell } from '@mui/material'
import AlbumTableRowActions from './components/AlbumTableRowActions'
import storesContext from 'providers/storesContext'
import {
  AlbumTableColumn,
  AlbumTableColumnId,
} from 'services/Interfaces/Album/AlbumTableColumn.interface'

type AlbumListRowProps = {
  album: Album
  updateCoverPhotograph: (id: string) => void
  addImagesToAlbum: (id: string) => void
  addDiscountToAlbum: (id: string) => void
  handleSaveDiscount: () => void
  showSuspendConfirmModal: (album: Album) => void
  handlePublishAlbum: (id: string) => void
  editAlbum: (album: Album) => void
  columns: readonly AlbumTableColumn[]
}

const AlbumTableRow = ({
  album,
  updateCoverPhotograph,
  addImagesToAlbum,
  addDiscountToAlbum,
  showSuspendConfirmModal,
  handlePublishAlbum,
  editAlbum,
  columns,
}: AlbumListRowProps) => {
  const { t } = useTranslation()

  const { authStore } = useContext(storesContext)!
  const loggedUser = authStore.getLoggedUser()

  const history = useHistory()
  const publishAlbum = (album: Album) => {
    handlePublishAlbum(album.id)
  }

  const handleEditAlbum = (album: Album) => {
    editAlbum(album)
  }

  const handleUpdateCoverPhotograph = () => {
    updateCoverPhotograph(album.id)
  }

  const handleAddPhotoButton = () => {
    addImagesToAlbum(album.id)
  }

  const handleAddDiscountButton = () => {
    addDiscountToAlbum(album.id)
  }

  const goToAlbumDetail = () => {
    history.push(myAlbumAlbumDetails(album.id))
  }

  const goToFeedAlbumDetail = () => {
    history.push(albumDetails(album.id))
  }

  return (
    <>
      {columns.map((column) => {
        if (column.id === AlbumTableColumnId.Location) {
          return (
            <TableCell style={{ minWidth: column.minWidth, padding: '6px' }} key={column.id}>
              {album.location.spotName}
            </TableCell>
          )
        } else if (column.id === 'date') {
          return (
            <TableCell style={{ minWidth: column.minWidth, padding: '6px' }} key={column.id}>
              {album.takenDate ? formatUtcToLocaleDate(album.takenDate) : ''}
            </TableCell>
          )
        } else if (column.id === AlbumTableColumnId.Activity) {
          return (
            <TableCell style={{ minWidth: column.minWidth, padding: '6px' }} key={column.id}>
              {album.activity.name ? t(album.activity.name) : '-'}
            </TableCell>
          )
        } else if (column.id === AlbumTableColumnId.PhotographCount) {
          return (
            <TableCell style={{ minWidth: column.minWidth, padding: '6px' }} key={column.id}>
              {album.photographCount}
            </TableCell>
          )
        } else if (column.id === AlbumTableColumnId.Description) {
          return (
            !isMobileDevice() && (
              <TableCell style={{ minWidth: column.minWidth, padding: '6px' }} key={column.id}>
                {album.description}
              </TableCell>
            )
          )
        } else if (column.id === AlbumTableColumnId.Status) {
          return (
            !isMobileDevice() && (
              <TableCell style={{ minWidth: column.minWidth, padding: '6px' }} key={column.id}>
                {album.isSuspended && loggedUser.isEnabledToSell ? (
                  <p className="p-0 m-0 text-warning">{t('Unpublished')}</p>
                ) : !loggedUser.isEnabledToSell ? (
                  <span className="p-0 m-0 text-warning">{t('Action required')}</span>
                ) : (
                  <p className="p-0 m-0 text-primary">{t('Published')}</p>
                )}
              </TableCell>
            )
          )
        } else if (column.id === AlbumTableColumnId.Actions) {
          return (
            <TableCell style={{ minWidth: column.minWidth, padding: '6px' }} key={column.id}>
              <AlbumTableRowActions
                album={album}
                showSuspendConfirmModal={showSuspendConfirmModal}
                handlePublishAlbum={publishAlbum}
                handleUpdateCoverPhotograph={handleUpdateCoverPhotograph}
                handleEditAlbum={handleEditAlbum}
                handleAddPhotoButton={handleAddPhotoButton}
                handleAddDiscountButton={handleAddDiscountButton}
                goToAlbumDetail={goToAlbumDetail}
                goToFeedAlbumDetail={goToFeedAlbumDetail}
                loggedUser={loggedUser}
              />
            </TableCell>
          )
        }
      })}
    </>
  )
}

export default observer(AlbumTableRow)
