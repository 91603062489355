import React, { useContext, useEffect, useRef, useState } from 'react'
import classes from './SearchBar.module.scss'
import { useTranslation } from 'react-i18next'
import deleteIcon from '../../../assets/icons/v2/Delete-Icon.svg'
import { observer } from 'mobx-react'
import SearchBarStore from './SearchBarStore'
import DateRangePicker from '../Forms/DateRangePicker'
import SearchBarDropdown, { DropdownOption } from './components/SearchBarDropdown'
import { isNil } from 'lodash'
import { useHistory, useLocation } from 'react-router'
import { FeatureFlags } from 'config/constants/featureFlags'
import * as paths from '../../../routing/Paths'
import StoresContext from '../../../providers/storesContext'
import Button from '../Button'
import moment from 'moment'
import { Event } from 'shared/models/Event'
import { getFeatureFlagValue } from 'shared/utility'
import { faSliders } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const AlbumSearchUrlQueryParams = {
  ActivityId: 'activityId',
  LocationId: 'locationId',
  CountryCode: 'countryCode',
  EventId: 'eventId',
  DateFrom: 'dateFrom',
  DateTo: 'dateTo',
}

export enum Filters {
  Country = 'Country',
  Location = 'Location',
  Activity = 'Activity',
  Event = 'Event',
  DateRange = 'DateRange',
}

type SearchBarProps = {
  searchBarStore: SearchBarStore
  onSearch: () => void
  filters: Filters[]
}

const SearchBar = ({ searchBarStore, onSearch, filters }: SearchBarProps) => {
  const location = useLocation()
  const history = useHistory()
  const [mobileFiltersActive, setMobileFiltersActive] = useState(false)
  const [clearInputs, setClearInputs] = useState(false)
  const { eventStore, activityStore, locationStore, featureFlagsStore } = useContext(StoresContext)!

  const initialRender = useRef(true)

  const { t } = useTranslation()

  const includesCountryInFilter = filters.includes(Filters.Country)

  const countryFilterEnabled =
    getFeatureFlagValue(featureFlagsStore, FeatureFlags.COUNTRY_FILTER, true) &&
    includesCountryInFilter

  const handleSearch = () => {
    updateURLWithFilters()
    onSearch()
  }

  useEffect(() => {
    if (!initialRender.current) {
      handleSearch()
    } else {
      initialRender.current = false
    }
  }, [
    searchBarStore.countryCode,
    searchBarStore.event,
    searchBarStore.location,
    searchBarStore.activity,
    searchBarStore.dateRange,
  ])

  const handleChangeLocation = (option?: DropdownOption | null) => {
    searchBarStore.setLocation(locationStore.getLocation(option?.value))
  }

  const handleChangeActivity = (option?: DropdownOption | null) => {
    searchBarStore.setActivity(activityStore.getActivity(option?.value))
  }

  const handleChangeDateRange = (value: [Date, Date]) => {
    const [dateFrom, dateTo] = value
    searchBarStore.setDateRange([dateFrom, dateTo])
  }

  const handleChangeEvent = (option: DropdownOption | null) => {
    const eventPath = option?.metadata?.eventPath

    const isMyAccountView = window.location.pathname === paths.MY_ACCOUNT

    if (!isNil(eventPath)) {
      if (eventPath === 'valentin-martinez-2023' || isMyAccountView) {
        searchBarStore.setEvent(eventStore.getEvent(option?.value))
      } else {
        const eventDetailsUrl = paths.feedEventDetailsByLandingPath(eventPath)
        history.push(eventDetailsUrl)
      }
    } else {
      searchBarStore.setEvent(eventStore.getEvent(option?.value))
    }
  }

  const handleClearFilters = () => {
    searchBarStore.reset()
    setClearInputs(true)
    handleSearch()
  }

  const toggleMobileFilters = () => {
    setMobileFiltersActive(!mobileFiltersActive)
  }

  const updateURLWithFilters = () => {
    const searchParams = new URLSearchParams()
    if (!isNil(searchBarStore)) {
      countryFilterEnabled &&
        !isNil(searchBarStore.countryCode) &&
        searchParams.set(AlbumSearchUrlQueryParams.CountryCode, searchBarStore.countryCode)
      !isNil(searchBarStore.location) &&
        searchParams.set(AlbumSearchUrlQueryParams.LocationId, searchBarStore.location.id)
      !isNil(searchBarStore.activity) &&
        searchParams.set(AlbumSearchUrlQueryParams.ActivityId, searchBarStore.activity.id)
      !isNil(searchBarStore.event) &&
        searchParams.set(AlbumSearchUrlQueryParams.EventId, searchBarStore.event.id)
      const [dateFrom, dateTo] = searchBarStore.dateRange
      !isNil(dateFrom) &&
        searchParams.set(
          AlbumSearchUrlQueryParams.DateFrom,
          moment.utc(dateFrom).format('DD-MM-YYYY')
        )
      !isNil(dateTo) &&
        searchParams.set(AlbumSearchUrlQueryParams.DateTo, moment.utc(dateTo).format('DD-MM-YYYY'))
    }

    const updatedURL = `${location.pathname}?${searchParams.toString()}`
    history.replace(updatedURL)
  }

  const atLeastOneFilterIsApplied =
    !isNil(searchBarStore.activity) ||
    !isNil(searchBarStore.location) ||
    !isNil(searchBarStore.event) ||
    !isNil(searchBarStore.dateRange[0]) ||
    !isNil(searchBarStore.dateRange[1])

  const availableEvents = eventStore.isLoadingEvents
    ? []
    : eventStore
        .getEventsForCountry(searchBarStore.countryCode)
        .slice()
        .sort((a: Event, b: Event) => {
          const dateA = new Date(a.date).getTime()
          const dateB = new Date(b.date).getTime()
          return dateB - dateA
        })
        .map((event) => ({
          key: event.id,
          value: event.id,
          label: event.name,
          metadata: {
            eventPath: event.landingPath,
          },
        }))

  const availableLocations = locationStore.isLoadingLocations
    ? []
    : locationStore
        .getLocationsForCountry(searchBarStore.countryCode)
        .slice()
        .sort((a, b) => a.spotName.localeCompare(b.spotName))
        .map((location) => ({
          value: location.id,
          key: location.id,
          label: location.spotName.toLocaleUpperCase(),
        }))

  const availableActivities = activityStore.isLoading
    ? []
    : activityStore.activities.map((activity) => ({
        value: activity.id,
        key: activity.id,
        label: t(activity.name),
      }))

  return (
    <>
      <div className="flex flex-row items-end justify-between w-full pb-2 md:hidden">
        <div className="flex w-3/4">
          {countryFilterEnabled && (
            <div className="flex flex-col items-start mr-1 w-44">
              <span className="mt-2 mb-1 font-medium">{t('Event')}</span>
              <SearchBarDropdown
                options={availableEvents}
                selectedValue={
                  !isNil(searchBarStore.event)
                    ? {
                        value: searchBarStore.event?.id,
                        label: searchBarStore.event.name,
                        metadata: {
                          eventPath: searchBarStore.event.landingPath,
                        },
                      }
                    : null
                }
                isLoading={eventStore.isLoadingEvents}
                onChange={handleChangeEvent}
                dropdownLabel={t('Event')}
              />
            </div>
          )}
          {filters.includes(Filters.Activity) && (
            <div className="flex flex-col items-start ml-1 w-44">
              <span className="mt-2 mb-1 font-medium">{t('Sport')}</span>
              <SearchBarDropdown
                options={availableActivities}
                selectedValue={
                  !isNil(searchBarStore.activity)
                    ? {
                        value: searchBarStore.activity?.id,
                        label: searchBarStore.activity.name,
                      }
                    : null
                }
                onChange={handleChangeActivity}
                dropdownLabel={t('Sport')}
                isLoading={activityStore.isLoading}
              />
            </div>
          )}
        </div>
        <div className="flex justify-end w-full pt-4 pb-1 h-50">
          <button type="button" onClick={toggleMobileFilters}>
            <FontAwesomeIcon icon={faSliders} size="2x" className="text-lumepic-light_black" />
          </button>
        </div>
      </div>
      <div className="md:hidden">
        {atLeastOneFilterIsApplied && !mobileFiltersActive && (
          <div className={classes.searchBarContainerClear}>
            <Button
              btnType={'TertiaryAction'}
              onClick={() => {
                handleClearFilters()
              }}
            >
              <img className="mr-2" loading="lazy" src={deleteIcon} alt="Click to delete filters" />
              {t('Delete filters')}
            </Button>
          </div>
        )}
      </div>
      <div className={[mobileFiltersActive ? classes.Open : classes.Close].join(' ')}>
        <div className="w-full mb-3">
          <div className="flex flex-col md:flex-row ">
            <div className="md:w-full">
              <div className="flex flex-col md:flex-row md:items-start">
                {filters.includes(Filters.Event) && (
                  <div className="hidden md:flex md:flex-col md:justify-center md:items-start md:self-end md:w-56 md:mr-2">
                    <span className="mt-2 mb-1 font-medium">{t('Event')}</span>
                    <SearchBarDropdown
                      options={availableEvents}
                      selectedValue={
                        !isNil(searchBarStore.event)
                          ? {
                              value: searchBarStore.event?.id,
                              label: searchBarStore.event.name,
                              metadata: {
                                eventPath: searchBarStore.event.landingPath,
                              },
                            }
                          : null
                      }
                      onChange={handleChangeEvent}
                      dropdownLabel={t('Event')}
                      isLoading={eventStore.isLoadingEvents}
                    />
                  </div>
                )}
                <div className="hidden md:flex md:flex-row md:justify-center md:items-start md:self-end md:gap-2 md:mr-2">
                  {filters.includes(Filters.Location) && (
                    <div className="w-full md:w-56">
                      <span className="mt-2 mb-1 font-medium">{t('Spot')}</span>
                      <SearchBarDropdown
                        options={availableLocations}
                        selectedValue={
                          !isNil(searchBarStore.location)
                            ? {
                                value: searchBarStore.location?.id,
                                label: searchBarStore.location.spotName,
                              }
                            : null
                        }
                        onChange={handleChangeLocation}
                        dropdownLabel={t('Spot')}
                        isLoading={locationStore.isLoadingLocations}
                      />
                    </div>
                  )}
                </div>
                {filters.includes(Filters.Activity) && (
                  <div className="hidden md:flex md:flex-col md:justify-center md:self-end md:items-start md:w-56 md:mr-2">
                    <span className="mt-2 mb-1 font-medium">{t('Sport')}</span>
                    <SearchBarDropdown
                      options={availableActivities}
                      selectedValue={
                        !isNil(searchBarStore.activity)
                          ? {
                              value: searchBarStore.activity?.id,
                              label: searchBarStore.activity.name,
                            }
                          : null
                      }
                      onChange={handleChangeActivity}
                      dropdownLabel={t('Sport')}
                      isLoading={activityStore.isLoading}
                    />
                  </div>
                )}

                <div className="md:hidden">
                  {filters.includes(Filters.Location) && (
                    <div className="w-full md:w-56">
                      <span className="mt-2 mb-1 font-medium">{t('Spot')}</span>
                      <SearchBarDropdown
                        options={availableLocations}
                        selectedValue={
                          !isNil(searchBarStore.location)
                            ? {
                                value: searchBarStore.location?.id,
                                label: searchBarStore.location.spotName,
                              }
                            : null
                        }
                        onChange={handleChangeLocation}
                        dropdownLabel={t('Spot')}
                        isLoading={locationStore.isLoadingLocations}
                      />
                    </div>
                  )}
                </div>
                {filters.includes(Filters.DateRange) && (
                  <div className="flex flex-col items-start justify-center md:w-56 md:mr-2">
                    <span className="mt-2 mb-1 font-medium">{t('Date Range')}</span>
                    <DateRangePicker
                      onChange={handleChangeDateRange}
                      value={searchBarStore.dateRange}
                      clearInputs={clearInputs}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex">
          {atLeastOneFilterIsApplied && (
            <div className={classes.searchBarContainerClear}>
              <Button
                btnType={'TertiaryAction'}
                onClick={() => {
                  handleClearFilters()
                }}
                extraStyle="px-0!"
              >
                <img
                  className="mr-2"
                  loading="lazy"
                  src={deleteIcon}
                  alt="Click to delete filters"
                />
                {t('Delete filters')}
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default observer(SearchBar)
