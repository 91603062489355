import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  formatThousandsAmount,
  formatUtcToLocaleDate,
  photographerDashboardColumns,
} from 'shared/utility'
import Album from 'shared/models/Album'
import { FeedOrderFields, FeedOrderQueryParams, FeedOrderSorts } from 'services/FeedService'
import SortByComponent from 'components/UI/SortByComponent/SortByComponent'
import { observer } from 'mobx-react'

type PhotographerAlbumsDataProps = {
  albums: Album[]
  albumCount: number
  albumsPerPage: number
  page: number
  setRowsPerPage: (albumsPerPage: number) => void
  onSortChange: React.Dispatch<React.SetStateAction<FeedOrderQueryParams>>
  sortOrder: FeedOrderQueryParams
  handleChangePage: (e: any, newPage: number) => void
}

const PhotographerAlbumsData = ({
  albums,
  albumCount,
  albumsPerPage,
  page,
  setRowsPerPage,
  onSortChange,
  sortOrder,
  handleChangePage,
}: PhotographerAlbumsDataProps) => {
  const { t } = useTranslation()

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
  }

  const handleSortChange = (field: FeedOrderFields, sort: FeedOrderSorts) => {
    onSortChange({ field, sort })
  }

  return (
    <div className="bg-white w-full py-4">
      <div className="py-2">
        <span className="flex font-semibold justify-center text-xl text-lumepic-light_black">
          {t('My Albums')}
        </span>
        <div className="flex justify-end">
          <SortByComponent handleSortChange={handleSortChange} sortOrder={sortOrder} />
        </div>
      </div>
      <Paper className="w-full overflow-hidden border">
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              {photographerDashboardColumns.map(({ id, label, minWidth }) => (
                <TableCell
                  key={id}
                  sx={{
                    fontWeight: '600',
                    fontSize: '1rem',
                    color: '#444444',
                    backgroundColor: '#F8F8F8',
                    textAlign: 'center',
                  }}
                  className={`min-w-[${minWidth}px] p-1.5`}
                >
                  {t(label)}
                </TableCell>
              ))}
            </TableHead>
            <TableBody>
              {albums.map((album, index) => (
                <TableRow key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-bg_details'}>
                  {[
                    formatUtcToLocaleDate(album.takenDate),
                    album.description,
                    album.activity.name,
                    album.photographCount,
                    album.salesCount,
                    ` ${formatThousandsAmount(album.netSalesAmountInUsd)}`,
                    album.photographsSold,
                    formatThousandsAmount(album.ratio),
                  ].map((content, cellIndex) => (
                    <TableCell key={cellIndex} sx={{ textAlign: 'center' }}>
                      {content <= 0 ? '-' : content}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={albumCount}
          rowsPerPage={albumsPerPage}
          page={page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            '& .MuiTablePagination-selectLabel': {
              marginBottom: '2px',
            },
            '& .MuiTablePagination-displayedRows': {
              marginBottom: '2px',
            },
          }}
        />
      </Paper>
    </div>
  )
}

export default observer(PhotographerAlbumsData)
