import { observer } from 'mobx-react'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import StoresContext from '../../providers/storesContext'
import { useTranslation } from 'react-i18next'
import 'moment/locale/es'
import classes from './AlbumDetails.module.scss'
import AlbumDetailsNoResult from '../../containers/MyAlbums/AlbumDetails/components/AlbumDetailsNoResult'
import PhotographModal from '../PhotographDetailsModal'
import {
  FEED_ALBUMS,
  feedAlbumDetailById,
  feedAlbumPhotographDetailById,
} from '../../routing/Paths'
import { isNil } from 'lodash'
import BackButton from 'components/UI/Button/BackButton'
import Tracker from '../../shared/tracking'
import AlbumDetailsStore from './AlbumDetailsStore'
import AlbumInformation from './components/AlbumInformation'
import AlbumSearchBar from '../UI/AlbumSearchBar'
import InfiniteScroll from 'react-infinite-scroll-component'
import Dropdown from 'components/UI/Dropdown'
import IntervalAccordion from '../UI/IntervalAccordion/IntervalAccordion'
import { useQuery } from 'hooks/useQuery'
import { DropdownOption } from 'components/UI/Dropdown/Dropdown'
import {
  BurstModeIntervalOption,
  PhotographUtils,
  TimeIntervalOptions,
  UnknownPhotographKey,
} from 'shared/util/photographs.utils'
import Checkbox from 'components/UI/Checkbox/Checkbox'
import Spinner from 'components/UI/Spinner'
import Button from 'components/UI/Button'
import { FeatureFlags } from 'config/constants/featureFlags'
import FaceRecognitionModal from './components/FaceRecognitionModal'
import deleteIcon from '../../assets/icons/v2/Delete-Icon.svg'
import {
  calculateTotalPhotographsPrice,
  formatPhotographCountText,
  getFeatureFlagValue,
  getFirstNumberOfPicturesForDiscount,
  getQtyDiscountPercentageEarned,
  isPackagePurchaseEnabledAfterQtyDiscountCalc,
  SetPageTitle,
} from 'shared/utility'
import SkeletonLoaderPhotographCard from 'components/UI/Loaders/Skeleton/SkeletonLoaderPhotographCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFaceSmile } from '@fortawesome/free-regular-svg-icons'
import DetailsPackageBar from './components/DetailsPackageBar'
import { TagKeys } from '../../services/Interfaces/Tag/Tag.interface'
import { toast } from 'react-toastify'
import { PackageType } from '../../services/Interfaces/Purchase/PurchaseRequest.interface'
import PrivateAlbumSection from './components/PrivateAlbum/PrivateAlbumSection'
import withErrorHandler from 'hoc/withErrorHandler/withErrorHandler'
import axios from '../../services/axios'
import useHideWhatsappButton from 'hooks/useHideWhatsappButton'

export type AlbumDetailsParams = {
  id: string
  photographId: string
}

const AlbumDetails = () => {
  useHideWhatsappButton()
  const { t } = useTranslation()
  const history = useHistory()
  const { authStore, cartStore } = useContext(StoresContext)!
  const { id, photographId } = useParams<AlbumDetailsParams>()
  const query = useQuery()
  const queryTags = query.getAll('tags')
  const page = Number(query.get('page'))
  const [albumDetailsStore] = useState(() => new AlbumDetailsStore(id, authStore, queryTags))
  const { album, photographs } = albumDetailsStore
  const [showUploadSelfieModal, setShowUploadSelfieModal] = useState(false)
  const initialRender = useRef(true)
  const [showBuyPackageButton, setShowBuyPackageButton] = useState(false)
  const [buyNowPrice, setBuyNowPrice] = useState<number | null>(null)
  const filtersApplied =
    albumDetailsStore.isFilteringByFaceRecognition || albumDetailsStore.tagIds.length > 0

  //FLAGS
  const { featureFlagsStore } = useContext(StoresContext)!

  const handlePackagePurchaseEnabled = getFeatureFlagValue(
    featureFlagsStore,
    FeatureFlags.ALBUMS_PACKAGE_PURCHASE,
    false
  )

  function handleRemoveFaceRecognitionFilter() {
    albumDetailsStore.setIsFilteringByFaceRecognition(false)
    albumDetailsStore.fetchPhotographs(true)
  }
  const defaultInterval: DropdownOption = { value: '60', label: '60 minutes' }

  const [photographToDisplayId, setPhotographToDisplayId] = useState<string | null>(null)
  const [selectedInterval, setSelectedInterval] = useState<DropdownOption>(defaultInterval)
  const [isBurstMode, setIsBurstMode] = useState<boolean>(false)

  if (!isNil(album.location.spotName)) {
    SetPageTitle(album.location.spotName)
  }

  useEffect(() => {
    setPhotographToDisplayId(photographId)
  }, [photographId, albumDetailsStore.isAuthenticated])

  useEffect(() => {
    albumDetailsStore.fetchAlbumDetails().then(() => {
      Tracker.viewAlbumDetails(album, photographs)
      if (!albumDetailsStore.album.isPrivate) {
        if (initialRender.current && !isNil(photographId)) {
          albumDetailsStore.fetchPhotograph(id, photographId)
          initialRender.current = false
        } else {
          albumDetailsStore.fetchPhotographs(true)
          initialRender.current = false
        }
      }
    })

    albumDetailsStore.fetchAlbumTags()
    if (!isNil(page) && page > 1) {
      albumDetailsStore.setPage(page)
    }
  }, [id, albumDetailsStore])

  useEffect(() => {
    if (!albumDetailsStore.isLoadingAlbumPhotographs) {
      const index = photographs.findIndex((photograph) => photograph.id === photographToDisplayId)
      if (index === -1) {
        setPhotographToDisplayId(null)
      } else if (photographs.length - 1 === index) {
        albumDetailsStore.fetchNextPage()
      }
    }
  }, [photographToDisplayId])

  useEffect(() => {
    if (!albumDetailsStore.isLoadingAlbumDetails) {
      albumDetailsStore.fetchPhotographs(true).then(() => {})
    }
  }, [id, albumDetailsStore.tagIds])

  const goBack = () => {
    if (history.length > 2) {
      history.goBack()
    } else {
      history.push(FEED_ALBUMS)
    }
  }

  const setPhotographModalPhotograph = (albumId: string, photographId: string) => {
    const url = feedAlbumPhotographDetailById(albumId, photographId)
    window.history.replaceState(null, '', url)
    setPhotographToDisplayId(photographId)
  }

  const setIntervalAccordionPhotographModalPhotograph = (photographId: string) => {
    const url = feedAlbumPhotographDetailById(album.id, photographId)
    window.history.replaceState(null, '', url)
    setPhotographToDisplayId(photographId)
  }

  const closeImageModal = () => {
    setPhotographToDisplayId(null)
    const initialUrl = feedAlbumDetailById(album!.id)
    const tagValueInUrl =
      albumDetailsStore.tagIds.length > 0 ? `?tagValue=${albumDetailsStore.tagIds}` : ''
    const urlWithTagIds = initialUrl + tagValueInUrl
    albumDetailsStore.tagIds
      ? window.history.replaceState(null, '', urlWithTagIds)
      : window.history.replaceState(null, '', initialUrl)
    if (photographs.length === 1) {
      albumDetailsStore.fetchPhotographs(true)
    }
    PhotographUtils.mantainScrollPositionAfterClosingModal(photographToDisplayId, photographs)
  }

  const handleChangeInterval = (value: DropdownOption) => {
    if (!isBurstMode) {
      setSelectedInterval(value)
    }
  }

  const handleBurstModeChange = (isChecked: boolean) => {
    setIsBurstMode(isChecked)
    if (isChecked) {
      setSelectedInterval(BurstModeIntervalOption)
    } else {
      setSelectedInterval(defaultInterval)
    }
  }

  const translatedTimeIntervalOptions = TimeIntervalOptions.map((option) => ({
    ...option,
    label: t(option.label),
  }))

  const downloadFreePhotograph = (photographId: string) => {
    if (!album.isFree) {
      return
    }
    Tracker.downloadFreePhotograph(photographId, album.id)
    albumDetailsStore.downloadFreePhotograph(photographId).then((response) => {
      if (response) {
        const link = document.createElement('a')
        link.href = response.url
        link.click()
      }
    })
  }

  const photographBlocksByInterval = PhotographUtils.buildPhotographsBlocksByInterval(
    photographs,
    selectedInterval
  )

  function fetchNextPage() {
    albumDetailsStore.fetchNextPage()
  }

  const loadingSpinner = <Spinner divStyles="flex justify-center mt-5 items-center" size={40} />

  if (albumDetailsStore.albumNotFound) {
    history.push('/feed/albums')
  }

  const handleBuyPackage = (price: number) => {
    if (albumDetailsStore.tagIds.length === 1 || albumDetailsStore.isFilteringByFaceRecognition) {
      const photographs = albumDetailsStore.photographs.map((photograph) => {
        return {
          id: photograph.id,
        }
      })
      let tagId: string | undefined
      if (albumDetailsStore.tagIds.length === 1) {
        tagId = albumDetailsStore.tagIds[0]
      }

      const appliesPackagePrice = price === album.defaultPackagePrice

      if (albumDetailsStore.isFilteringByFaceRecognition) {
        // Find the common tag in all photographs which has type face
        //TODO: Possible issue here if all the photographs more than one face tag in common
        const faceTag = albumDetailsStore.photographs
          .map((photograph) => photograph.tags)
          .reduce((acc, tags) => {
            return acc.filter((tag) => tags.some((t) => t.id === tag.id))
          })
          .find((tag) => tag.key === TagKeys.Face)
        if (isNil(faceTag)) {
          toast.error(t('Face tag not found'))
        }
        tagId = faceTag!.id //TODO: change to value once is updated in the API
      }
      Tracker.addPackagePaymentInformation(photographs, albumDetailsStore.album)
      if (appliesPackagePrice) {
        cartStore.createPackagePurchase(photographs, PackageType.Tag, tagId).then((purchase) => {
          if (!isNil(purchase)) {
            window.location.href = purchase.paymentUrl!
          }
        })
      } else {
        cartStore.createPackagePurchase(photographs).then((purchase) => {
          if (!isNil(purchase)) {
            window.location.href = purchase.paymentUrl!
          }
        })
      }
    }
  }

  const handleAddPackage = (price: number) => {
    if (albumDetailsStore.tagIds.length === 1 || albumDetailsStore.isFilteringByFaceRecognition) {
      let tagId: string | undefined
      if (albumDetailsStore.tagIds.length === 1) {
        tagId = albumDetailsStore.tagIds[0]
      }
      const appliesPackagePrice = price === album.defaultPackagePrice

      if (albumDetailsStore.isFilteringByFaceRecognition) {
        // Find the common tag in all photographs which has type face
        //TODO: Possible issue here if all the photographs more than one face tag in common
        const faceTag = albumDetailsStore.photographs
          .map((photograph) => photograph.tags)
          .reduce((acc, tags) => {
            return acc.filter((tag) =>
              tags.some((t) => t.id === tag.id || t.taggedUserId == tag.taggedUserId)
            )
          })
          .find((tag) => tag.key === TagKeys.Face)
        if (isNil(faceTag)) {
          toast.error(t('Face tag not found'))
        }
        tagId = faceTag!.id
      }
      Tracker.addPackagePaymentInformation(photographs, albumDetailsStore.album)
      if (appliesPackagePrice) {
        cartStore.addPackage(
          albumDetailsStore.photographs,
          albumDetailsStore.album.currency!,
          albumDetailsStore.album.defaultPackagePrice,
          PackageType.Tag,
          tagId
        )
      } else {
        for (const photograph of photographs) {
          if (!isNil(album)) {
            cartStore.addPhotograph(photograph, album)
          }
        }
      }
    }
  }

  const memoizedValues = useMemo(() => {
    const totalPhotographsPrice = calculateTotalPhotographsPrice(albumDetailsStore.photographs)

    const totalPhotographsPriceAfterDiscounts = !isNil(album.quantityDiscount)
      ? (1 -
          getQtyDiscountPercentageEarned(album.quantityDiscount, albumDetailsStore.photographs) /
            100) *
        totalPhotographsPrice
      : 0

    const packagePurchaseEnabledAfterQtyDiscountCalc = isPackagePurchaseEnabledAfterQtyDiscountCalc(
      totalPhotographsPrice,
      totalPhotographsPriceAfterDiscounts,
      albumDetailsStore.album.defaultPackagePrice ?? undefined,
      albumDetailsStore.album.quantityDiscount ?? undefined
    )

    const minNumberOfPhotosForQtyDiscount = !isNil(album.quantityDiscount)
      ? getFirstNumberOfPicturesForDiscount(album.quantityDiscount)
      : null

    return {
      totalPhotographsPrice,
      totalPhotographsPriceAfterDiscounts,
      packagePurchaseEnabledAfterQtyDiscountCalc,
      minNumberOfPhotosForQtyDiscount,
    }
  }, [filtersApplied, album.quantityDiscount, photographs, album.defaultPackagePrice])

  const {
    totalPhotographsPriceAfterDiscounts,
    packagePurchaseEnabledAfterQtyDiscountCalc,
    minNumberOfPhotosForQtyDiscount,
  } = memoizedValues

  const displayBuyAllButtonEnabled =
    packagePurchaseEnabledAfterQtyDiscountCalc || minNumberOfPhotosForQtyDiscount

  useEffect(() => {
    if (
      filtersApplied &&
      !albumDetailsStore.isLoadingAlbumPhotographs &&
      !isNil(minNumberOfPhotosForQtyDiscount) &&
      albumDetailsStore.photographs.length >= minNumberOfPhotosForQtyDiscount
    ) {
      if (displayBuyAllButtonEnabled) {
        const tag = albumDetailsStore.photographs
          .map((photograph) => photograph.tags)
          .reduce((acc, tags) => acc.filter((tag) => tags.some((t) => t.id === tag.id)))
          .find((tag) => tag)

        const isTextTagOrNil = !tag || tag.key === TagKeys.Text

        if (
          albumDetailsStore.tagIds &&
          isTextTagOrNil &&
          albumDetailsStore.photographs.length >= 20
        ) {
          setShowBuyPackageButton(false)
        } else if (!isNil(album.defaultPackagePrice) && handlePackagePurchaseEnabled) {
          setBuyNowPrice(album.defaultPackagePrice)
          setShowBuyPackageButton(true)
        } else if (totalPhotographsPriceAfterDiscounts > 0) {
          setBuyNowPrice(totalPhotographsPriceAfterDiscounts)
          setShowBuyPackageButton(true)
        }
      } else {
        setShowBuyPackageButton(false)
      }
    } else {
      setShowBuyPackageButton(false)
    }
  }, [
    albumDetailsStore.isFilteringByFaceRecognition,
    albumDetailsStore.isFilteringByFaceRecognition,
    albumDetailsStore.isLoadingAlbumPhotographs,
  ])

  const content = (
    <>
      <div className="mb-3 rounded-md bg-bg_details md:bg-white">
        {!albumDetailsStore.isLoadingAlbumDetails && <AlbumInformation album={album} />}
      </div>
      <div className="px-1 py-2 bg-white rounded-md">
        <div className="flex flex-col gap-2 mb-2 px-1.5 sm:flex-row sm:justify-between sm:items-center">
          <div className="flex flex-col w-full gap-2 ml-px sm:w-1/2 md:w-1/3 lg:w-1/4">
            <AlbumSearchBar albumDetailsStore={albumDetailsStore} />
          </div>
          <div className="flex flex-col w-full gap-2 sm:w-1/2 md:w-1/3">
            <div className="flex flex-col gap-1">
              <span className="text-sm text-lumepic-grey">{t('Time interval')}</span>
              <div className="flex items-center sm:gap-2">
                <div className="w-1/2 sm:w-full">
                  <Dropdown
                    label={t('Time interval').toString()}
                    onChange={handleChangeInterval}
                    options={translatedTimeIntervalOptions}
                    disabled={isBurstMode}
                    value={selectedInterval}
                    extraStyles={{
                      fieldset: {
                        borderColor: '#636363',
                        color: '#636363',
                        borderWidth: '2px',
                      },
                    }}
                  />
                </div>
                <div className="w-1/2 sm:w-full">
                  <Checkbox
                    id="burst-mode"
                    label={t('View bursts')}
                    value={isBurstMode}
                    onChange={handleBurstModeChange}
                  />
                </div>
              </div>
            </div>
          </div>
          {album.faceRecognitionProcessed && (
            <div className="flex flex-col gap-3 mt-2 sm:mt-0">
              <Button btnType={'PrimaryAction'} onClick={() => setShowUploadSelfieModal(true)}>
                <div className="flex items-center gap-2">
                  <div className="flex items-center justify-center p-1 border-2 border-black border-dashed rounded-md">
                    <FontAwesomeIcon icon={faFaceSmile} className="text-black" />
                  </div>
                  <span className="">{t('Filter by face recognition')}</span>
                </div>
              </Button>
              {albumDetailsStore.isFilteringByFaceRecognition && (
                <button
                  onClick={() => {
                    handleRemoveFaceRecognitionFilter()
                  }}
                  className="flex items-center gap-2"
                >
                  <span>{t('Remove filter')}</span>
                  <img
                    className="w-3"
                    loading="lazy"
                    src={deleteIcon}
                    alt="Click to remove filter"
                  />
                </button>
              )}
            </div>
          )}
        </div>
        {albumDetailsStore.isFilteringByFaceRecognition &&
          !albumDetailsStore.isLoadingAlbumPhotographs && (
            <div className="mx-2 mt-4">
              {t('You’ve appeared in') +
                ' ' +
                formatPhotographCountText(albumDetailsStore.photographCount, t)}
              !
            </div>
          )}
      </div>
      {!albumDetailsStore.isLoadingAlbumPhotographs &&
        !albumDetailsStore.isLoadingAlbumDetails &&
        (!albumDetailsStore || photographs.length === 0) && <AlbumDetailsNoResult />}
      {!albumDetailsStore.isLoadingAlbumDetails && photographs.length > 0 && (
        <InfiniteScroll
          dataLength={photographs.length}
          next={fetchNextPage}
          hasMore={albumDetailsStore.hasMorePages()}
          loader={<></>}
          scrollThreshold={0.8}
          className="overflow-hidden"
        >
          {Array.from(photographBlocksByInterval.keys())
            .sort(PhotographUtils.sortIntervals)
            .map((key) => {
              const photographBlockInterval = photographBlocksByInterval.get(key)
              const burstNumber = key.substring(6)
              const translatedKey = isBurstMode ? `${t('Burst')} ${burstNumber}` : key
              return (
                <IntervalAccordion
                  key={key}
                  index={isBurstMode ? translatedKey : key}
                  unknownPhotographKey={UnknownPhotographKey}
                  setPhotograph={setIntervalAccordionPhotographModalPhotograph}
                  downloadFreePhotograph={downloadFreePhotograph}
                  photographBlockInterval={photographBlockInterval}
                  isInBurstMode={isBurstMode}
                  albumDetailsStore={albumDetailsStore}
                  allPhotographsFromSameDay={true}
                />
              )
            })}
        </InfiniteScroll>
      )}
      {albumDetailsStore.isLoadingAlbumPhotographs && albumDetailsStore.page > 1 && loadingSpinner}
      {albumDetailsStore.isLoadingAlbumPhotographs && albumDetailsStore.page === 1 && (
        <SkeletonLoaderPhotographCard />
      )}

      {!isNil(photographToDisplayId) && (
        <PhotographModal
          photographToDisplayId={photographToDisplayId}
          setPhotographToDisplayId={setPhotographModalPhotograph}
          album={album}
          photographs={photographs}
          loadingNextPage={albumDetailsStore.isLoadingAlbumPhotographs}
          onClose={closeImageModal}
          onFeed
          onDownloadFreePhotograph={downloadFreePhotograph}
        />
      )}
      <FaceRecognitionModal
        opened={showUploadSelfieModal}
        onCancel={() => setShowUploadSelfieModal(false)}
        store={albumDetailsStore}
        onSuccess={() => albumDetailsStore.fetchPhotographs(true)}
      />
    </>
  )

  if (!album || cartStore.isLoading) {
    return <Spinner />
  }

  return (
    <div className={classes.FullAlbumContainer + ' overflow-hidden mb-24 mt-3'}>
      <div className="container mx-auto">
        <div className="mb-1.5">
          <BackButton
            onClick={goBack}
            extraStyle={
              'px-2 py-1 rounded-md bg-white text-lumepic-grey hover:text-black transition duration-200'
            }
          />
        </div>
        {(!album.isPrivate || (album.isPrivate && albumDetailsStore.isAuthenticated)) && (
          <>{content}</>
        )}
        {album.isPrivate && !albumDetailsStore.isAuthenticated && (
          <PrivateAlbumSection
            store={albumDetailsStore}
            initialRender={initialRender}
            albumId={id}
            photographId={photographId}
          />
        )}
      </div>
      {showBuyPackageButton && !isNil(buyNowPrice) && (
        <DetailsPackageBar
          handleBuyPackage={() => handleBuyPackage(buyNowPrice)}
          photographBlocksByInterval={photographBlocksByInterval}
          price={buyNowPrice}
          album={album}
          isFilteringByFaceRecognition={albumDetailsStore.isFilteringByFaceRecognition}
          handleAddPackage={() => handleAddPackage(buyNowPrice)}
        />
      )}
    </div>
  )
}

export default withErrorHandler(observer(AlbumDetails), axios)
